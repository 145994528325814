import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Geomap } from "d3plus-react"
import Layout from "../components/layout"
import { connect } from "react-redux"
import "./continent.scss"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { selectAll } from "d3-selection"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Loading from "../components/loading"
import fetch from "cross-fetch"

import AsiaTopojson from "../data/topojson/continents/asia.json"
import AfricaTopojson from "../data/topojson/continents/africa.json"

const mapStateToProps = ({ subcontinent, jwt, isLoggedin }) => {
  return { subcontinent, jwt, isLoggedin }
}

const mapDispatchToProps = dispatch => {
  return {
    select_subcontinent: (subcontinent, url) =>
      dispatch({
        type: `SELECT_SUBCONTINENT`,
        subcontinent,
        url,
      }),
  }
}

const mapHeight = 400
const mapWidth = 500

var countryData = []
var countryHighlight = []

const titleCase = string => {
  var sentence = string.toLowerCase().split(" ")
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
  }

  return sentence.join(" ")
}

const ContinentPage = ({
  data,
  pageContext,
  reset_admin,
  select_subcontinent,
  jwt,
  isLoggedin,
  
}) => {
  // reset_admin()
  useEffect(() => {
    if (!isLoggedin) {
      navigate("/")
    }
  })

  select_subcontinent(pageContext.continent, pageContext.url)

  const uniqueCountries = [
    ...new Set(
      data.allLocationsJson.edges.map(data => data.node.level_0.toLowerCase())
    ),
  ]

  for (var idx in uniqueCountries) {
    const country = uniqueCountries[idx]
    const country_sentenceCase = titleCase(country)
    countryHighlight.push(country_sentenceCase)
    countryData.push({
      geounit: country_sentenceCase,
      value: country_sentenceCase,
    })
  }

  // Client-side Runtime Data Fetching
  const [description, setContinentDescription] = useState(0)
  useEffect(() => {
    // get data from GitHub api
    if (isLoggedin) {
      fetch(`https://bigdata-in-ag.uc.r.appspot.com/subcontinents`, {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${jwt}`,
        // },
      })
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          const fromStrapi = resultData.filter(
            d => d.name.toLowerCase() === pageContext.continent.toLowerCase()
          )[0].description

          setContinentDescription(fromStrapi)
        })
    }
  })

  if (!isLoggedin) {
    return <div />
  } else {
    console.log(pageContext)
    return (
      <Layout>
        <Container class="continent-container">
          <Row>
            <h1>{pageContext.continent}</h1>
          </Row>
          <Row>
            <Col md={5} lg={5}>
              {pageContext.continent.toLowerCase() === "east africa" ? (
                <Geomap
                  config={{
                    data: countryData,
                    groupBy: "geounit",
                    shapeConfig: {
                      Path: {
                        fill: d => {
                          return countryHighlight.includes(d.geounit)
                            ? "rgba(62, 168, 168, 0.9)"
                            : "rgba(0, 0, 0, 0.75)"
                        },
                        stroke: "rgba(255,255,255,0.75)",
                      },
                    },
                    on: {
                      "click.shape": d => {
                        selectAll(".d3plus-tooltip").remove()
                        if (d.geounit) {
                          const url = d.geounit.toLowerCase().replace(/ /g, "")
                          navigate(`/${url}`)
                        }
                      },
                    },
                    tooltip: true,
                    tooltipConfig: {
                      background: "white",
                      borderRadius: "5px",
                      height: "2em",
                      title: d => {
                        var tooltipTitle = ""
                        if (d.geounit) {
                          tooltipTitle = d.geounit
                        }
                        return tooltipTitle
                      },
                    },
                    downloadButton: false,
                    // width: mapWidth,
                    // height: mapHeight,
                    detectResize: true,

                    topojson: AfricaTopojson,
                    zoom: false,
                    legend: null,
                    tiles: false,
                    ocean: "transparent",
                    topojsonId: d => d.properties.geounit,
                    topojsonFilter: d => {
                      const eastAfrica = [
                        "Djibouti",
                        "Eritrea",
                        "Ethiopia",
                        "Somalia",
                        "Sudan",
                        "South Sudan",
                        "Madagascar",
                        "Mauritius",
                        "Comoros",
                        "Seychelles",
                        "French Southern and Antarctic Lands",
                        "Réunion",
                        "Mayotte",
                        "Uganda",
                        "Rwanda",
                        "Burundi",
                        "Kenya",
                        "Tanzania",
                        "Mozambique",
                        "Malawi",
                        "Zambia",
                        "Zimbabwe",
                      ]

                      return eastAfrica.includes(d.properties.geounit)
                    },
                  }}
                />
              ) : (
                <Geomap
                  config={{
                    data: countryData,
                    groupBy: "geounit",
                    shapeConfig: {
                      Path: {
                        fill: d => {
                          return countryHighlight.includes(d.geounit)
                            ? "rgba(62, 168, 168, 0.9)"
                            : "rgba(0, 0, 0, 0.75)"
                        },
                        stroke: "rgba(255,255,255,0.75)",
                      },
                    },
                    on: {
                      "click.shape": d => {
                        if (d.geounit) {
                          const url = d.geounit.toLowerCase().replace(/ /g, "")
                          selectAll(".d3plus-tooltip").remove()
                          navigate(`/${url}`)
                        }
                      },
                    },
                    tooltip: true,
                    tooltipConfig: {
                      background: "white",
                      borderRadius: "5px",
                      height: "2em",
                      title: d => {
                        var tooltipTitle = ""
                        if (d.geounit) {
                          tooltipTitle = d.geounit
                        }
                        return tooltipTitle
                      },
                    },
                    downloadButton: false,
                    width: mapWidth,
                    height: mapHeight,
                    topojson: AsiaTopojson,
                    zoom: false,
                    legend: null,
                    tiles: false,
                    ocean: "transparent",
                    topojsonId: d => d.properties.geounit,
                    topojsonFilter: d => {
                      const southAsia = [
                        "Afghanistan",
                        "Bangladesh",
                        "Bhutan",
                        "India",
                        "Maldives",
                        "Nepal",
                        "Pakistan",
                        "Sri Lanka",
                      ]

                      return southAsia.includes(d.properties.geounit)
                    },
                  }}
                />
              )}
            </Col>
            <Col md={7} lg={7}>
              <Row>
                <h2>About</h2>
              </Row>
              <Row>
                <div className="continent_background">
                  {description ? (
                    <ReactMarkdown source={description} />
                  ) : (
                    <Loading />
                  )}
                </div>
              </Row>
              <Row>
                  {pageContext.countries.map(country => {
                    if (
                      uniqueCountries.find(
                        result => result === country.name.toLowerCase()
                      )
                    ) {
                      return (
                        <Col md={3}>
                          <Link to={"/" + country.url}>
                            <div
                              className="region-button"
                              id={
                                country.name.toLowerCase().replace(/ /g, "") +
                                "-button"
                              }
                            >
                              <div className="region-button-text">
                                {country.name}
                              </div>
                            </div>
                          </Link>
                        </Col>
                      )
                    } else {
                      return false
                    }
                  })}
              
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export const query = graphql`
  query CountryQuery {
    allLocationsJson {
      edges {
        node {
          subcontinent
          level_0
        }
      }
    }
  }
`
export default connect(mapStateToProps, mapDispatchToProps)(ContinentPage)
